<template>
    <div>
        <div
            v-if="!loading"
        >
            <div
                v-if="!dataFound"
            >
                <b-alert
                    show
                    variant="info"
                >
                    <h4
                        class="alert-heading"
                    >
                        Whoops!
                    </h4>
                    <div
                        class="alert-body"
                    >
                        Sorry, but we didn't find any data that we could process for this dashboard!<br>
                        Most likely the reason is that you didn't have shipments done or quotes ran.
                    </div>
                </b-alert>
            </div>
            <div
                v-else
            >
                <b-card no-body>
                    <b-card-header>
                        <div>
                            <b-card-title class="mb-1">
                                General
                            </b-card-title>
                            <b-card-sub-title>
                                Line chart for monthly total prepared & confirmed shipments.
                            </b-card-sub-title>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <chartjs-line-chart
                            :height="400"
                            :plugins="plugins"
                            :data="tableData"
                        />
                    </b-card-body>
                </b-card>
                <b-row>
                    <b-col
                        md="6"
                        sm="6"
                    >
                        <b-card
                            title="Last five quotes"
                        >
                            <div
                                v-if="lastFiveQuotes.length <= 0"
                            >
                                <b-alert
                                    show
                                    variant="info"
                                >
                                    <h4
                                        class="alert-heading"
                                    >
                                        Whoops!
                                    </h4>
                                    <div
                                        class="alert-body"
                                    >
                                        Sorry, but we didn't find any quotes generated!<br>
                                    </div>
                                </b-alert>
                            </div>
                            <app-timeline
                                v-for="(shipment, index) in lastFiveQuotes"
                                v-else
                                :key="index"
                            >
                                <app-timeline-item
                                    icon="DollarSignIcon"
                                    variant="info"
                                >
                                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                        <h6>Quote ran. Destination: <b>{{ shipment['destination_country'] }}</b>, origin: <b>{{ shipment['origin_country'] }}</b></h6>
                                        <small class="text-muted">{{ timeAgo(new Date(shipment['quote_ran'])) }}</small>
                                    </div>
                                    <p>
                                        Quote was run under eSchenker nu-price calculator.<br>
                                        Expected freighter charge is <b>{{ shipment['freight_charge'] }}{{ shipment['pricing_currency'] }}</b>, while delivery charge is <b>{{ shipment['total_price'] - shipment['freight_charge'] }}{{ shipment['pricing_currency'] }}</b>, resulting in total of<br>
                                        <b>{{ shipment['total_price'] }}{{ shipment['pricing_currency'] }}</b>, considering that delivery will be made in <b>5 work days.</b>
                                    </p>
                                </app-timeline-item>
                            </app-timeline>
                        </b-card>
                    </b-col>
                    <b-col
                        md="6"
                        sm="6"
                    >
                        <b-card
                            title="Last five shipments"
                        >
                            <div
                                v-if="lastFiveShipments.length <= 0"
                            >
                                <b-alert
                                    show
                                    variant="info"
                                >
                                    <h4
                                        class="alert-heading"
                                    >
                                        Whoops!
                                    </h4>
                                    <div
                                        class="alert-body"
                                    >
                                        Sorry, but we didn't find any shipments processed yet!<br>
                                    </div>
                                </b-alert>
                            </div>
                            <app-timeline
                                v-for="(shipment, index) in lastFiveShipments"
                                v-else
                                :key="index"
                            >
                                <app-timeline-item
                                    icon="TruckIcon"
                                    variant="success"
                                >
                                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                        <h6>Shipment sent to <b>{{ shipment['shipped_to'] }}</b>, postcode <b>{{ shipment['to_postcode'] }}</b></h6>
                                        <small class="text-muted">{{ timeAgo(new Date(shipment['shipped_date'])) }}</small>
                                    </div>
                                    <p>
                                        Shipment successfully registered under eSchenker.<br>
                                        Calculated shipment weight: <b>{{ shipment['shipment_weight'] }}</b>kg, with total of <b>{{ shipment['package_count'] }}</b> packages within the shipment.<br>
                                        Estimated delivery is <b>5 work days</b> from shipping date.
                                    </p>
                                </app-timeline-item>
                            </app-timeline>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        md="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="TruckIcon"
                            color="success"
                            :statistic="mostCommonCountry"
                            statistic-title="Most shipments sent to"
                        />
                    </b-col>
                    <b-col
                        md="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="CastIcon"
                            color="info"
                            :statistic="averageShipmentsWeight + ' kg'"
                            statistic-title="Average weight per shipment"
                        />
                    </b-col>
                    <b-col
                        md="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="DollarSignIcon"
                            color="secondary"
                            :statistic="quotesTotal + '€'"
                            statistic-title="Quotes total"
                        />
                    </b-col>
                    <b-col
                        md="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="PackageIcon"
                            color="danger"
                            :statistic="averagePackageCount"
                            statistic-title="Average package count per shipment"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="TruckIcon"
                            color="primary"
                            :statistic="totalShipments"
                            statistic-title="Total shipments"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="CastIcon"
                            color="info"
                            :statistic="totalQuotes"
                            statistic-title="Total quotes"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="PackageIcon"
                            color="danger"
                            :statistic="totalPackages"
                            statistic-title="Total packages"
                        />
                    </b-col>
                </b-row>
            </div>
        </div>
        <div
            v-else
            class="row mx-auto justify-content-center align-items-center flex-column"
        >
            <b-spinner
                style="width: 2rem; height: 2rem; margin-top: 20px;"
                variant="primary"
            />
        </div>
    </div>
</template>

<script>
const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

import {
    BCard,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BAlert,
    BRow,
    BCol,
    BSpinner,
} from 'bootstrap-vue'
import Axios from 'axios'
import { $themeColors } from '@themeConfig'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ChartjsLineChart from '@/components/Charts/ChartjsLineChart'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'


export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardSubTitle,
        BCardTitle,
        BAlert,
        BSpinner,
        BRow,
        BCol,
        StatisticCardHorizontal,
        ChartjsLineChart,
        AppTimeline,
        AppTimelineItem,
    },
    data() {
        return {
            shipmentData: [],
            dataFound: false,
            loading: false,
            tableData: [],
            possibleDates: [],
            dateValuesForShipments: [],
            quote_data: [],
            shipment_data: [],
            mostCommonCountry: '',
            averageShipmentsWeight: 0,
            quotesTotal: 0,
            lastFiveShipments: [],
            lastFiveQuotes: [],
            totalQuotes: 0,
            totalShipments: 0,
            averagePackageCount: 0,
            totalPackages: 0,
            plugins: [
                {
                    beforeInit(chart) {
                        /* eslint-disable func-names, no-param-reassign */
                        chart.legend.afterFit = function () {
                            this.height += 20
                        }
                        /* eslint-enable */
                    },
                },
            ],
        }
    },
    created() {
        this.setTableData()
    },
    methods: {
        setTableData() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}schenker/get-dashboard-data`,
                method: 'POST',
                data: { }
            }).then(resp => {
                this.shipmentData.push(resp.data)

                if(!('quote_data' in this.shipmentData[0]) && !('shipment_data' in this.shipmentData[0])
                    || this.shipmentData[0]['quote_data'].length <= 0 && this.shipmentData[0]['shipment_data'].length <= 0) {
                    this.loading = false
                    this.dataFound = false
                    return
                }

                this.shipment_data = this.shipmentData[0]['shipment_data']
                this.quote_data = this.shipmentData[0]['quote_data']
                this.mostCommonCountry = (new Intl.DisplayNames(['en'], {type: 'region'})).of(this.shipmentData[0]['shipment_data_additional']['most_shipped_to'])
                this.averageShipmentsWeight = this.shipmentData[0]['shipment_data_additional']['average_weight']
                this.quotesTotal = this.shipmentData[0]['shipment_data_additional']['total_quote_price']
                this.averagePackageCount = this.shipmentData[0]['shipment_data_additional']['average_package_count']
                this.lastFiveShipments = this.shipmentData[0]['shipment_data_additional']['last_five_shipments']
                this.lastFiveQuotes = this.shipmentData[0]['shipment_data_additional']['last_five_quotes']
                this.totalQuotes = this.shipmentData[0]['shipment_data_additional']['total_quotes']
                this.totalShipments = this.shipmentData[0]['shipment_data_additional']['total_shipments']
                this.totalPackages = this.shipmentData[0]['shipment_data_additional']['total_packages']

                this.shipment_data.forEach(value => {
                    const date = new Date(value.created_at)
                    const month = (date.getMonth() + 1).toString().padStart(2, '0')
                    if(this.possibleDates.indexOf(`${date.getFullYear()}-${month}`) === -1)
                        this.possibleDates.push(`${date.getFullYear()}-${month}`)
                })

                this.possibleDates.forEach(value => {
                    this.dateValuesForShipments.push(this.shipment_data.filter(order => order.created_at.includes(value)).length)
                })

                this.tableData = {
                    labels: this.possibleDates,
                    datasets: [
                        {
                            data: this.dateValuesForShipments,
                            label: 'Shipments',
                            borderColor: chartColors.warningLightColor,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: chartColors.warningLightColor,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 5,
                            pointHoverBorderWidth: 5,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: chartColors.warningLightColor,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                        },
                    ]
                }
                this.loading = false
                this.dataFound = true
            }).catch(err => {
                this.loading = false
                this.dataFound = false
            })
        },
        timeAgo(date) {
            const NOW = new Date()
            const times = [["second", 1], ["minute", 60], ["hour", 3600], ["day", 86400], ["week", 604800], ["month", 2592000], ["year", 31536000]]

            let diff = Math.round((NOW - date) / 1000)
            for (let t = 0; t < times.length; t++) {
                if (diff < times[t][1]) {
                    if (t === 0) {
                        return "Just now"
                    } else {
                        diff = Math.round(diff / times[t - 1][1])
                        return diff + " " + times[t - 1][0] + (diff === 1 ? " ago" : "s ago")
                    }
                }
            }
        }
    }
}
</script>