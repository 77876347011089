<script>
import { Line } from 'vue-chartjs'
import { $themeColors } from '@themeConfig'
import Axios from 'axios'

const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
    extends: Line,
    props: {
        data: {
            type: Object,
            default() {
                return this.setDataForTable
            },
        },
        options: {
            type: Object,
            default() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    backgroundColor: $themeColors.white,
                    hover: {
                        mode: 'label',
                    },
                    tooltips: {
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowBlur: 8,
                        shadowColor: chartColors.tooltipShadow,
                        backgroundColor: '#ffffff',
                        titleFontColor: '#000000',
                        bodyFontColor: '#000000',
                    },
                    layout: {
                        padding: {
                            top: -15,
                            bottom: -25,
                            left: -15,
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                    display: true,
                                },
                                gridLines: {
                                    display: true,
                                    color: chartColors.grid_line_color,
                                    zeroLineColor: chartColors.grid_line_color,
                                },
                                ticks: {
                                    fontColor: chartColors.labelColor,
                                },
                            },
                        ],
                    },
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                            },
                            ticks: {
                                stepSize: 100,
                                min: 0,
                                max: 400,
                                fontColor: chartColors.labelColor,
                            },
                            gridLines: {
                                display: true,
                                color: chartColors.grid_line_color,
                                zeroLineColor: chartColors.grid_line_color,
                            },
                        },
                    ],
                    legend: {
                        position: 'top',
                        align: 'start',
                        labels: {
                            usePointStyle: true,
                            padding: 25,
                            boxWidth: 9,
                        },
                    },
                }
            },
        },
        plugins: {
            type: Array,
            default: null,
        },
        styles: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        this.renderChart(this.data, this.options, this.plugins, this.styles)
    },
    methods: {
        async setDataForTable() {
            const shipmentsData = []
            await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments`,
                method: 'POST',
                data: {
                    page: 1,
                    take: 150,
                    search: '',
                    order_by: [
                        {
                            col: 'created_at',
                            dir: 'desc',
                        },
                    ],
                },
            }).then(resp => {
                shipmentsData.push(resp.data)
            }).catch(() => {})
            let june = 0
            let july = 0
            let august = 0
            let september = 0
            let october = 0
            let november = 0
            let december = 0
            let january = 0
            let february = 0
            let march = 0
            setTimeout(() => {
                june = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-06')).length
                july = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-07')).length
                august = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-08')).length
                september = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-09')).length
                october = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-10')).length
                november = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-11')).length
                december = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2020-12')).length
                january = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2021-01')).length
                february = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2021-02')).length
                march = shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes('2021-03')).length
            }, 4200)
            this.data = {
                labels: ['2020-06', '2020-07', '2020-08', '2020-09', '2020-10', '2020-11', '2020-12', '2021-01', '2021-02', '2021-03'],
                datasets: [
                    {
                        data: [june, july, august, september, october, november, december, january, february, march],
                        label: 'Shipments',
                        borderColor: chartColors.warningLightColor,
                        lineTension: 0.5,
                        pointStyle: 'circle',
                        backgroundColor: chartColors.warningLightColor,
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 5,
                        pointHoverBorderWidth: 5,
                        pointBorderColor: 'transparent',
                        pointHoverBorderColor: $themeColors.white,
                        pointHoverBackgroundColor: chartColors.warningLightColor,
                        pointShadowOffsetX: 1,
                        pointShadowOffsetY: 1,
                        pointShadowBlur: 5,
                        pointShadowColor: chartColors.tooltipShadow,
                    },
                ],
            }
        },
    },
}
</script>
